import React from "react"
import { graphql } from "gatsby"
import Layout from "../components/layout"
import Seo from "../components/seo"
import Hero from "../components/hero"
import PageFascia from "../components/page-fascia"
import HeroIcons from "../components/hero-icons"
import withPreview from "../utility/with-preview"
import pageUrl from "../utility/page-url"


const PaginaInternaTemplate = ({ data: { page, meta }, pageContext: { langCode }, location }) => {
  const { pageTitle, htmlTitle, hero, fasce } = page;
  const canonical = (htmlTitle.content && htmlTitle.content.canonicalUrl && htmlTitle.content.canonicalUrl.value) 
    || `${meta.siteUrl}/${pageUrl(page)}`;
  return (
    <Layout langCode={langCode} location={location} tags={page.tags}>
      <Seo title={htmlTitle.value || pageTitle.value} langCode={langCode} ogImage={htmlTitle.content.ogImage}  
        description={htmlTitle.content && htmlTitle.content.metaDescription && htmlTitle.content.metaDescription.value}
        skipEn={pageTitle.content && pageTitle.content.skipEn} canonical={canonical}
        robots={htmlTitle.content && htmlTitle.content.metaRobots && htmlTitle.content.metaRobots.value} />
      {hero ? (
        hero.__typename === 'LiferaySiteHeader_Hero' ? 
          <Hero data={hero} page={page} /> : <HeroIcons data={hero} page={page} />
      ): null}
      {fasce ? fasce.map((fascia, i) => <PageFascia key={i} data={fascia} />) : null}
    </Layout>
  );
}

export default withPreview(PaginaInternaTemplate, { subField: 'page', fixed: true });

export const query = graphql`query PaginaInternaQuery($liferayId: Int!, $langCode: String!){
  meta: gatsbySourceLiferayOptions {
    siteUrl
  }
  page: liferayPaginainterna(liferayId: { eq: $liferayId}, langCode: { eq: $langCode}) {
    siteId
    articleId
    langCode
    pageTitle {
      value
      content {
        skipEn
        friendlyUrl {
          value
        }
      }
    }
    htmlTitle {
      value
      content {
        metaDescription {
          value
        }
        canonicalUrl {
          value
        }
      }
      ...PaginainternaTitleFragment
    }
    hero {
      __typename
      ...SiteHeader_HeroFragment
      ...SiteHeader_Hero_IconeFragment
    }
    ...BreadcrumbPaginainternaFragment
    ...FasceFragment
    tags: taxonomyCategoryBriefs {
      taxonomyCategoryId
      taxonomyCategoryName
    }
  }
}`
